var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer-table-container px-0 mx-0"},[_c('b-table',_vm._g({staticClass:"table-list",attrs:{"responsive":"","fixed":"","no-local-sorting":"","items":_vm.customers,"fields":_vm.fields,"sort-by":_vm.queryParams.sortBy,"sort-desc":_vm.queryParams.sortOrder === 'desc'},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({width: _vm.colWidths[field.key]})})})}},{key:"head(updated)",fn:function(data){return [_c('div',{staticClass:"light-border-right"},[_c('div',{staticClass:"d-flex column-explanation white-space-pre"},[_c('div',[_c('span',[_vm._v(_vm._s(data.label))]),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(_vm._s(_vm.t('dateHint')))])])])])]}},{key:"cell(cartDuration)",fn:function(ref){
var index = ref.index;
return [_c('b-form-input',{staticClass:"text-center",attrs:{"autocomplete":"off","lazy":"","min":"1","max":"30","state":_vm.validateState(_vm.$v.customers.$each.$iter[index].cartDuration),"formatter":function (val, $event) { return _vm.formatInput(val, $event, 2); }},model:{value:(_vm.$v.customers.$each.$iter[index].cartDuration.$model),callback:function ($$v) {_vm.$set(_vm.$v.customers.$each.$iter[index].cartDuration, "$model", _vm._n($$v))},expression:"$v.customers.$each.$iter[index].cartDuration.$model"}})]}},{key:"cell(maxLenses)",fn:function(ref){
var index = ref.index;
return [_c('b-form-input',{staticClass:"text-center",attrs:{"autocomplete":"off","lazy":"","min":"1","max":"4","state":_vm.validateState(_vm.$v.customers.$each.$iter[index].maxLenses),"formatter":function (val, $event) { return _vm.formatInput(val, $event, 1); }},model:{value:(_vm.$v.customers.$each.$iter[index].maxLenses.$model),callback:function ($$v) {_vm.$set(_vm.$v.customers.$each.$iter[index].maxLenses, "$model", _vm._n($$v))},expression:"$v.customers.$each.$iter[index].maxLenses.$model"}})]}},{key:"cell(hidePatientNames)",fn:function(ref){
var item = ref.item;
return [_c('b-row',{attrs:{"align-h":"center"}},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],model:{value:(item.hidePatientNames),callback:function ($$v) {_vm.$set(item, "hidePatientNames", $$v)},expression:"item.hidePatientNames"}})],1)]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"type":"button","variant":"primary","disabled":_vm.isRowDefault(item) || _vm.$v.customers.$each.$iter[index].$invalid},on:{"click":function($event){return _vm.updateCustomer(item, index)}}},[_vm._v(_vm._s(_vm.t('save')))])],1)]}}])},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }