<template>
    <div class="customer-table-container px-0 mx-0">
        <b-table
            responsive
            fixed
            no-local-sorting
            class="table-list"
            :items="customers"
            :fields="fields"
            :sort-by="queryParams.sortBy"
            :sort-desc="queryParams.sortOrder === 'desc'"
            v-on="$listeners"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>

            <template #head(updated)="data">
                <div class="light-border-right">
                    <div class="d-flex column-explanation white-space-pre">
                        <div>
                            <span>{{ data.label }}</span>
                            <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
                        </div>
                    </div>
                </div>
            </template>

            <template #cell(cartDuration)="{index}">
                <b-form-input
                    class="text-center"
                    autocomplete="off"
                    lazy
                    min="1"
                    max="30"
                    :state="validateState($v.customers.$each.$iter[index].cartDuration)"
                    :formatter="(val, $event) => formatInput(val, $event, 2)"
                    v-model.number="$v.customers.$each.$iter[index].cartDuration.$model"
                ></b-form-input>
            </template>

            <!--template #cell(reservationDuration)="{item}">
                <b-form-input
                    class="text-center"
                    type="number"
                    min="1"
                    max="30"
                    v-model.number="item.reservationDuration"
                ></b-form-input>
            </template-->

            <template #cell(maxLenses)="{index}">
                <b-form-input
                    class="text-center"
                    autocomplete="off"
                    lazy
                    min="1"
                    max="4"
                    :state="validateState($v.customers.$each.$iter[index].maxLenses)"
                    :formatter="(val, $event) => formatInput(val, $event, 1)"
                    v-model.number="$v.customers.$each.$iter[index].maxLenses.$model"
                ></b-form-input>
            </template>

            <template #cell(hidePatientNames)="{item}">
                <b-row align-h="center">
                    <b-form-checkbox v-model="item.hidePatientNames" v-b-tooltip.hover>
                    </b-form-checkbox>
                </b-row>
            </template>

            <template #cell(action)="{item, index}">
                <div class="d-flex justify-content-center">
                    <b-button
                        type="button"
                        variant="primary"
                        :disabled="isRowDefault(item) || $v.customers.$each.$iter[index].$invalid"
                        @click="updateCustomer(item, index)"
                        >{{ t('save') }}</b-button
                    >
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import date from '@/filters/date';
import {formatInput} from '@/utilities/formatters';
import {required, between} from 'vuelidate/lib/validators';

export default {
    name: 'CustomerTable',
    props: {
        queryParams: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            colWidths: {
                //based on a 1536px width resolution screen
                updated: '104px',
                name: '200px',
                ocosCustomerNumber: '122px',
                country: '92px',
                cartDuration: '122px',
                //reservationDuration: '120px',
                maxLenses: '120px',
                hidePatientNames: '163px',
                action: '80px',
            },
            customersClone: [],
        };
    },
    validations: {
        customers: {
            $each: {
                cartDuration: {
                    required,
                    between: between(1, 30),
                },
                maxLenses: {
                    required,
                    between: between(1, 4),
                },
            },
        },
    },
    computed: {
        ...mapState({
            customers: (state) => state.customers.list,
        }),
        fields() {
            return [
                {
                    key: 'updated',
                    label: this.t('activity'),
                    sortable: true,
                    tdClass: 'white-space-pre',
                    formatter: (value) => date(value),
                },
                {
                    key: 'name',
                    label: this.t('customer_Name'),
                    sortable: true,
                    tdClass: 'font-weight-bold',
                    stickyColumn: true,
                },
                {key: 'ocosCustomerNumber', sortable: true, label: this.t('customer_Id')},
                {key: 'country', sortable: true, label: this.t('checkout_Country')},
                {
                    key: 'cartDuration',
                    label: this.t('cart_Duration'),
                },
                /*{
                    key: 'reservationDuration',
                    label: this.t('reservation_Duration'),
                },*/
                {key: 'maxLenses', label: this.t('max_Lenses'), tdClass: 'np'},
                {
                    key: 'hidePatientNames',
                    label: this.t('patient_names'),
                },
                {
                    key: 'action',
                    label: '',
                },
            ];
        },
    },
    watch: {
        customers() {
            this.customersClone = cloneDeep(this.customers);
        },
    },
    methods: {
        isRowDefault(customer) {
            const customerClone = this.customersClone.find(
                (c) => c.customerId == customer.customerId
            );
            return JSON.stringify(customerClone) === JSON.stringify(customer);
        },
        formatInput(value, event, maxLength = 1) {
            return formatInput(value, event, [], 0, maxLength);
        },
        validateState(validator) {
            const {$invalid, $dirty} = validator;
            return $dirty ? !$invalid : null;
        },
        async updateCustomer(customer, index) {
            try {
                await this.blockingRequest('customers/update', {
                    customerId: customer.customerId,
                    payload: pick(customer, [
                        //'reservationDuration',
                        'cartDuration',
                        'maxLenses',
                        'hidePatientNames',
                    ]),
                });
                this.$v.customers.$each.$iter[index].$reset();
                this.$emit('refresh-records');
            } catch (err) {
                alert('Unable to update Customer');
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
