<template>
    <b-card>
        <b-row class="mb-2">
            <b-col xl="4" lg="12" class="h-80">
                <h4 class="heavy mb-0">{{ t('custDesc1') }}</h4>
                <p class="gray-dark mb-0">{{ t('custDesc2') }}</p>
                <p v-if="isDefaultSort" class="gray-dark">
                    {{ t('custDesc3') }}
                </p>
            </b-col>

            <b-col xl="5" lg="8" class="order-1">
                <b-form @submit.prevent="searchChanged" class="d-flex flex-column flex-sm-row">
                    <b-input-group class="px-0 mr-2 mb-2 mb-xl-0">
                        <b-input-group-prepend is-text>
                            <b-icon icon="search"></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                            type="search"
                            v-model="queryParams.search"
                            :placeholder="t('nameOrId')"
                            id="searchInput"
                        ></b-form-input>
                    </b-input-group>

                    <div class="justify-content-end">
                        <b-button type="submit" variant="primary"> {{ t('search') }} </b-button>
                    </div>
                </b-form>
            </b-col>
            <b-col xl="3" lg="4" class="order-0 order-lg-2 mb-2">
                <div class="d-flex justify-content-lg-end"></div>
            </b-col>
        </b-row>

        <CustomerTable
            :queryParams="queryParams"
            @sort-changed="sortChanged"
            @refresh-records="refreshRecords"
        />

        <b-row>
            <b-col cols="2" offset="10">
                <b-pagination
                    v-if="pageDetails.total"
                    @change="pageChange"
                    align="right"
                    size="sm"
                    :value="queryParams.page"
                    :total-rows="pageDetails.total"
                    :per-page="queryParams.perPage"
                ></b-pagination>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import merge from 'lodash/merge';
import {SortCategories} from '@/constants/customer';
import CustomerTable from './components/CustomerTable.vue';

const defaultSort = {
    sortOrder: 'desc',
    sortBy: SortCategories.ACTIVITY,
};
const defaultQueryParams = () => ({
    search: null,
    page: 1,
    perPage: 10,
    ...defaultSort,
});

export default {
    name: 'CustomerList',
    components: {
        CustomerTable,
    },
    data() {
        return {
            queryParams: defaultQueryParams(),
        };
    },
    computed: {
        ...mapState({
            pageDetails: (state) => state.customers.details,
        }),
        isDefaultSort() {
            return (
                defaultSort.sortOrder === this.queryParams.sortOrder &&
                defaultSort.sortBy === this.queryParams.sortBy
            );
        },
    },

    methods: {
        ...mapActions('customers', ['clearList']),
        async searchChanged() {
            if (!this.queryParams.search) this.queryParams = defaultQueryParams();
            this.queryParams.page = 1;
            await this.refreshRecords();
        },
        async sortChanged(context) {
            if (!context.sortBy) return;
            this.queryParams.page = 1;
            this.queryParams.sortBy = context.sortBy;
            this.queryParams.sortOrder = context.sortDesc ? 'desc' : 'asc';
            await this.refreshRecords();
        },
        async pageChange(page) {
            this.queryParams.page = page;
            await this.refreshRecords();
        },
        async refreshRecords() {
            this.$router
                .replace({
                    query: this.queryParams,
                })
                .catch(() => {});
            await this.blockingRequest('customers/fetchList', {...this.queryParams});
        },
    },
    created() {
        this.clearList();
    },
    async mounted() {
        this.queryParams = merge({}, this.queryParams, this.$route.query);
        await this.refreshRecords();
    },
};
</script>
<style></style>
